window.Filters = {
  selectFilterSelector: '.list_filters select',
  dateFilterSelector: '.list_filters input.hasDatepicker[type=text]',
  keepParamSelector: '.list_filters input[type=hidden][data-keep-param]',
  formSelector: '.list_filters form',
  changeEvents: 'change change.select2',
  submitEvents: 'ajax:beforeSend',
  ajaxCompleteEvents: 'ajax:complete',

  get selectFilters() { return $(this.selectFilterSelector) },
  get dateFilters() { return $(this.dateFilterSelector) },
  get keepParamInputs() { return $(this.keepParamSelector) },

  init: () => {
    Filters.updateHighlighting();
  },

  reset: (event) => {
    Filters.selectFilters.prop('selectedIndex', 0).trigger("change.select2");
    Filters.dateFilters.val(null);
    Filters.onChange(event);
  },

  onChange: (event) => {
    Filters.updateHighlighting();
    Filters.updateKeepParams();
    // Don't make a request on change.select2 events
    if(!event.namespace) Filters.updateList(event);
  },

  onSubmit: (event) => {
    event.currentTarget.classList.add('working');
  },

  ajaxComplete: (event) => {
    event.currentTarget.classList.remove('working');
  },

  updateList: (event) => {
    console.log('updating list...')
    Rails.handleRemote.call(event.currentTarget.form, event);
  },

  updateHighlighting: () => {
    Filters.selectFilters.each((_i, el) => {
      $(el).toggleClass('active', !!$(el).find('option:selected:not(:first-child)').length)
    })
    Filters.dateFilters.each((_i, el) => {
      $(el).toggleClass('active', !!$(el).val());
    })
  },

  updateKeepParams: () => {
    // Commented out since this is supposed to be polyfilled by core-js@3, uncomment if not.
    // if(typeof URLSearchParams === 'undefined') return true; // URLSearchParams is not in IE
    let params = new URL(window.location).searchParams;
    Filters.keepParamInputs.each((_i, el) => {
      if(el.dataset.keepParam === 'true') el.value = params.get(el.name);
    })
  }
}

$(document).on('click', '#filter_reset', Filters.reset);

// Clear button for datepicker, probably belongs elsewhere
$(document).on('click', '.ui-datepicker-close', function(_event){
  $($.datepicker._curInst.input).val(null).trigger('change');
})

$(document).on(Filters.changeEvents, Filters.selectFilterSelector, Filters.onChange);
$(document).on(Filters.changeEvents, Filters.dateFilterSelector, Filters.onChange);
$(document).on(Filters.submitEvents, Filters.formSelector, Filters.onSubmit);
$(document).on(Filters.ajaxCompleteEvents, Filters.formSelector, Filters.ajaxComplete);
