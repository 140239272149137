window.VisualSchedule = {
  VisualScheduleInstance: {
    mouseDown: false,
    startRowIndex: null,
    startCellIndex: null,

    init: function() {
      let $table = $(this.table);
      $table.on('mousedown', 'div.cell', this.mousedown.bind(this));
      $table.on('mouseover', 'div.cell', this.mouseover.bind(this));
      $table.on('click', 'div.cell', this.clickFlip.bind(this));
      $(document).on('mouseup', this.mouseup.bind(this));
      return this;
    },

    clickFlip: function(e) {
      let cell = $(e.target);
      if (cell.hasClass("uncheck")) {
        cell.removeClass("uncheck").addClass("check");
        cell.siblings()[1].checked = true;
      } else if (cell.hasClass("check")) {
        cell.removeClass("check").addClass("uncheck");
        cell.siblings()[1].checked = false;
      }
    },

    mousedown: function(e) {
      e.preventDefault();
      this.mouseDown = true;
      let cell = $(e.target);

      this.startCellIndex = cell.parent().index();
      this.startRowIndex = cell.parent().parent().index();
    },

    mouseover: function(e) {
      if (!this.mouseDown) return;
      this.selectTo($(e.target));
    },

    mouseup: function() {
      this.mouseDown = false;
      $(this.table).find(".stay").removeClass("stay");
    },

    selectTo: function(cell) {
      let row = cell.parent().parent();
      let cellIndex = cell.parent().index();
      let rowIndex = row.index();
      let $table = $(this.table);

      let rowStart, rowEnd, cellStart, cellEnd;

      if (rowIndex < this.startRowIndex) {
        rowStart = rowIndex;
        rowEnd = this.startRowIndex;
      } else {
        rowStart = this.startRowIndex;
        rowEnd = rowIndex;
      }

      if (cellIndex < this.startCellIndex) {
        cellStart = cellIndex;
        cellEnd = this.startCellIndex;
      } else {
        cellStart = this.startCellIndex;
        cellEnd = cellIndex;
      }

      for (let i = rowStart; i <= rowEnd; i++) {
        let rowCells = $table.find("tbody").find("tr").eq(i).find("td");
        for (let j = cellStart; j <= cellEnd; j++) {
          let group = rowCells.eq(j).find("div");
          let cbs = rowCells.eq(j).find("input[type=checkbox]")[0];
          if (group.hasClass("uncheck") && !group.hasClass("stay")) {
            group.addClass("check").addClass("stay").removeClass("uncheck");
            cbs.checked = true;
            $table.find(".check").addClass("stay");
          }
          if (group.hasClass("check") && !group.hasClass("stay")) {
            group.addClass("uncheck").addClass("stay").removeClass("check");
            cbs.checked = false;
            $table.find(".uncheck").addClass("stay");
          }
        }
      }
    },
  },

  gatherScheduleData: function() {
    let schedule = {};
    let day, hour, x, y;
    $("#schedule_table div.cell").each(function(i, obj){
      day = $(obj).data('day');
      hour = $(obj).data('hour');
      x = {"sunday": 0, "monday": 1, "tuesday": 2, "wednesday": 3, "thursday": 4, "friday": 5, "saturday": 6}[day];
      y = parseInt(hour);
      schedule[x+"_"+y] = $(obj).hasClass("check");
    });
    return schedule;
  },

  scheduleResetState: null,

  resetScheduleData: function() {
    let day, hour, cb_display, cb;
    if(!this.scheduleResetState) this.scheduleResetState = this.gatherScheduleData();

    for(let key in this.scheduleResetState){
      day = ["sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday"][key.split("_")[0]];
      hour = key.split("_")[1];
      cb_display = $("#"+day+"_"+hour);
      cb = cb_display.siblings('input[type=checkbox]')[0];
      if (this.scheduleResetState[key]) {
        cb.checked = true
        cb_display.removeClass("uncheck").addClass("check");
      }
      else {
        cb.checked = false;
        cb_display.removeClass("check").addClass("uncheck");
      }
    }
  },

  attach: function(element) {
    if(element.visualScheduleInstance) return true;
    let instance = $.extend(true, {}, this.VisualScheduleInstance, { table: element });
    element.visualScheduleInstance = instance.init();
  },
};

$(document).on('mouseenter', '.schedule_table', function(e){
  VisualSchedule.attach(e.currentTarget);
});
