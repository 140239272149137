$(document).on('click', '.submit2', function(event) {
  let button, form, method;
  button = event.currentTarget;
  if(Rails.getData(button, 'ujs:disabled')) return;

  form = $(button).parents('form')[0];
  method = button.getAttribute('data-method') || form.method;

  button.setAttribute('data-remote', true);
  button.setAttribute('data-method', method);
  button.setAttribute('data-params', Rails.serializeElement(form))
  Rails.handleRemote.bind(button)(event);
})
