/**
 * Bootstrap Popovers
 * Enable them on page load and shim ActiveScaffold before sorting to disable them
 * */

$(function () {
  $('[data-toggle="popover"]').popover()
});

/**
 * as:element_updated =>  form updated because validations fail
 *                        form field changed because a chained field changed
 *                        ajax inplace edit is open
 *                        singular subform is replaced
 *                        list is refreshed
 *  as:element_created => subform record in a plural subform is added
 *                        new record row added to list
 **/
$(document).on( 'as:element_updated as:element_created', (e) => {
  $('[data-toggle="popover"]').popover()
});

/**
 * Because sort causes a page refresh the popover can be triggered and the DOM reference lost
 * leaving a 'dead' popover open just hanging around. So we disable popovers for the duration
 * of the call. ActiveScaffold will then reinitialize them (indirectly) after the page is refreshed.
 **/
$( document ).on( 'ajax:before', () => {
  $( '[data-toggle="popover"]' ).popover( 'disable' );
});
