window.AutoRefresh = {
  activityFlag: false,
  activityEvents: ['mousemove', 'keypress', 'mousedown', 'DOMMouseScroll', 'mousewheel', 'scroll'],

  scopedEventTypes: function(){
    return this.activityEvents.map(function(event){ return event + '.auto_refresh' }).join(' ');
  },

  attemptRefresh: function(){
    if(!!this.activityFlag) {
      this.activityFlag = false;
      $(document).on(this.scopedEventTypes(), this.activityDetected.bind(this));
    } else {
      if(document.visibilityState === 'hidden') return;
      $.getScript(document.location)
    }
  },

  activityDetected: function() {
    // using scoped event types and running `off` manually because multi-event `one` calls bind multiple handlers to
    // events that are not the one that fires, causing a memory leak
    $(document).off(this.scopedEventTypes());
    this.activityFlag = true;
  },

  start: function(intervalMinutes){
    this.stop();
    this.activityFlag = true;
    this.intervalPromise = setInterval(this.attemptRefresh.bind(this), intervalMinutes*60*1000);
  },

  stop: function(){
    clearInterval(this.intervalPromise);
  }
}
