
// Prevents clicks for checkboxes that collapse sections until the section is completely shown/hidden
$(document).on('click.bs.collapse.data-api', 'input[type=checkbox][data-toggle=collapse]', function(e){
  const $this = $(e.currentTarget);
  const $target = $($this.data('target'));
  if(!$target.length) return;

  const halt = function(e) { e.preventDefault(); };
  $this.on('click', halt)
  $target.one('shown.bs.collapse hidden.bs.collapse', $.fn.off.bind($this, 'click', halt));
})
