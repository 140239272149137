/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
//

// @babel version 7.4.0 deprecated @babel/polyfill in favor of directly including this. We're currently using @babel
// 6.23.0, but @babel/polyfill doesn't seem like it was brought in. For now, we're going to use `core-js` directly.
// https://babeljs.io/docs/en/babel-polyfill
import 'core-js/stable'

// Allows access to jquery in the window context. Also jquery-ui does not initialize correctly if jQuery is provided
// as $ in the environment.js. This means that we don't have the $ alias set until we call jQuery the first time.
const jQuery = require('jquery')

import 'jquery-ui-dist/jquery-ui.js'
import 'jquery-ui-dist/jquery-ui.css'
import 'bootstrap'
import 'bootstrap-icons/font/bootstrap-icons.scss'
import '../../assets/javascripts/select2/select2.min'
import 'react-dom'

import '../active_scaffold/export'

import '../src/popovers'
import '../src/styles/application.scss'
import '../src/tooltip'
import '../src/filters_handling'
import '../src/no_autofill'
import '../src/explicit_edit'
import '../src/auto_refresh'
import '../src/select2_extensions'
import '../src/visual_schedule'
import '../src/submit2'
import '../src/onenter'
import '../src/bootstrap_extensions'
require('../setup_alert_count_links');

global.$ = global.jQuery = jQuery;
window.$ = window.jQuery = jQuery;
