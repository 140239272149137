function setEmailDisplay() {
  document.getElementById("email_fields").style.display = (document.getElementById("send_email").checked ? "block" : "none" );
}

function changeEmailDisplay() {
  document.getElementById("export_email_form").style.display = (document.getElementById("send_email").checked ? "block" : "none" );
  document.getElementById("export_download_form").style.display = (document.getElementById("send_email").checked ? "none" : "block" );
}

// closes export form after 2 seconds, see line 126 of active_scaffold.js for more info
$(document).on('submit', 'form.as_form.export', function(e) {
  let $cancel = $(e.currentTarget).find('.as_cancel');
  setTimeout(function() { $cancel.trigger('ajax:before') }, 2000);
})
