window.matchParentOrChild = function(params, data) {
  // If there are no search terms, return all of the data
  if($.trim(params.term) === '') return data;

  // if the top level matches, return everything
  if(data.text.toUpperCase().indexOf(params.term.toUpperCase()) >= 0) return data;

  // Skip if there is no 'children' property to match
  if(typeof data.children === 'undefined') return null;

  // return the children that match
  let filteredChildren = [];
  $.each(data.children, function (idx, child) {
    if(child.text.toUpperCase().indexOf(params.term.toUpperCase()) >= 0) filteredChildren.push(child);
  })

  // If we matched any of the group's children, then set the matched children on the group
  // and return the group object
  if(filteredChildren.length) {
    var modifiedData = $.extend({}, data, true);
    modifiedData.children = filteredChildren;
    return modifiedData;
  }
  // Return `null` if the term should not be displayed
  return null;
}

$.fn.select2.defaults.set('matcher', matchParentOrChild);

// Hack to autofocus the select2 search field (have to go around jQuery)
// selecting the last one in case clicking on a select2 from a select2
$(document).on('select2:open', function(e) {
  let searchFields = document.querySelectorAll('input.select2-search__field')
  searchFields[searchFields.length - 1].focus() // focus the last one
})

$(document).on('select2:unselect', function(e) {
  $(document).one('select2:open', function() { $(this).select2('close'); }.bind(e.target))
})
